<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div class="mx-2 mt-2 lg:mt-0">
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
            <router-link class="btn-green" to="/admin/skapa-reseller">
              {{ $t("reseller_page.new_dealer") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="columns"
          :rows="resellers"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <router-link class="text-primary-500 font-bold underline hover:text-primary-600 transition duration-200" :to="'/reseller/' + props.row._id">
                {{ props.row.name }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'edit'">
              <button class="btn-secondary-outline" @click.prevent="show(props.row)">
                {{ $t("reseller_page.edit") }}
                <BaseIcon icon="pencil-alt" class="ml-1" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'agreement'">
              <router-link tag="button" class="btn-blue-outline" :to="'/mina-avtal/reseller/' + props.row._id">
                {{ $t("reseller_page.agreement") }}
                <BaseIcon icon="file-alt" class="ml-1" />
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'customer'">
              <router-link tag="button" class="btn-blue-outline" :to="'/kunder/reseller/' + props.row._id">
                {{ $t("reseller_page.customer") }}
                <BaseIcon icon="users" class="ml-1" />
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <ResellerUpdate :resellerInfo="updateInfo" @updateReseller="getResellers()" />
  </div>
</template>

<script>
import ResellerUpdate from "@/components/modal/reseller_update";

export default {
  title() {
    return this.$t("page_titles.reseller");
  },
  components: {
    ResellerUpdate,
  },
  data() {
    return {
      user: this.$store.state.user,
      resellers: [],
      reseller_info: null,
      updateInfo: null,
    };
  },
  computed: {
    columns() {
      return [
        { label: this.$t("reseller_table.name"), field: "name", sortable: false },
        { label: this.$t("reseller_table.email"), field: "email", sortable: false },
        { label: this.$t("reseller_table.phone"), field: "phone", sortable: false },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "agreement", sortable: false },
        { label: "", field: "customer", sortable: false },
      ];
    },
  },
  methods: {
    getResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/reseller/`)
        .then((response) => {
          this.resellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    exportXLS() {
      let export_columns = [];

      this.resellers.forEach((reseller) => {
        export_columns.push({
          Namn: reseller.name,
          Email: reseller.email,
          Telefon: reseller.phone,
          Description: reseller.description,
        });
      });

      this.excelExport(export_columns, "Återförsäljare", "reseller_rapport");
    },

    show(reseller) {
      this.updateInfo = _.cloneDeep(reseller);
      this.$modal.show("update-reseller");
    },
  },

  created() {
    this.getResellers();
    this.setPageTitle("Admin - Återförsäljare", "resellers_admin");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.reseller");
    },
  },
};
</script>
